import React from 'react'
import { Box, Input, Text, Heading } from 'theme-ui'
import Icon from '@solid-ui-components/ContentIcon'


const styles = {
    wrapper: {
        flex: [`100%`, null, null, 1],
        height: 30,
        color: "black",
        border:"0.5px solid gray",
        bg:"white"
    }
}



const TeklifInput = ({ placeholder, icon, value, name, id,tip, ...props }) => (
    <Box>
        <Box> {placeholder}</Box>
        <Box variant='forms.field'>
            <Icon content={icon} size='xs' round ml='3' pl='2' />

            <Input
                {...props}
                onWheel={ event => event.currentTarget.blur() }
                sx={styles.wrapper} value={value} name={name} id={id}
                type={tip??"text"}
            />
        </Box>
    </Box>
)
export default TeklifInput
