import React, { useEffect, useContext, useState } from 'react'
import { Container, Flex, Box, Badge, Text } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import httpServiceToken from '../../../../../site/src/service/httpServiceToken'
import ContentContainer from '@solid-ui-components/ContentContainer'
import {
  AiOutlineCloudDownload, AiOutlineFileDone, AiOutlineFileText, AiOutlineNumber, AiFillContacts, AiFillProject,
  AiOutlineControl, AiTwotoneShop, AiOutlineMacCommand, AiOutlineLayout, AiOutlineBuild
} from "react-icons/ai";
import Counter from '@solid-ui-components/Counter'
import Divider from '@solid-ui-components/Divider'

import { Chart, registerables } from 'chart.js';
import Slider from '@solid-ui-blocks/Slider/Block01'
import { setKtTokenSifre } from '../../../../../site/src/service/auth'
import Tabs from '@solid-ui-components/Tabs'
import { TabPanel } from 'react-tabs'
import KarOranlariEdit from '@solid-ui-blocks/Maliyet/KarOranlari'



const styles = {
  linkBtn: {
    fontSize: "0.9em",
    textDecoration: "underline",
    cursor: "pointer"
  },
  wrapper: {
    flex: [`100%`, null, null, 1],
    minWidth: 215,
    maxWidth: [`none`, null, null, 355],
    // mx:2,
    // my:0,  
  },

  wrapper1: {
    flex: [`100%`, null, null, 1],
    minWidth: 95,
    maxWidth: [`none`, null, null, 175],

  },
  wrapper2: {
    flex: [`100%`, null, null, 1],
    minWidth: 210,
    maxWidth: [`none`, null, null, 380],
  },
  card: {
    overflow: `hidden`,
    margin: "15px",
    height: "200px"
  },
  cardYarim: {
    overflow: `hidden`,
    margin: "15px",
    height: "150px"
  },
  cardhead1: {
    alignItems: `center`,
    justifyContent: `space-evenly`,
    height: "65px",
    color: "white",
    background: t => `
    linear-gradient(
      225deg,
      ${t.colors.alpha} 82%,
      ${t.colors.alphaLight} 28%)
   `,
  },
  cardhead2: {
    alignItems: `center`,
    justifyContent: `space-evenly`,
    height: "65px",
    color: "white",
    background: t => `
    linear-gradient(
      225deg,
      ${t.colors.beta} 82%,
      ${t.colors.betaLight} 28%)
   `,
  },
  cardheadSiparisler: {
    alignItems: `center`,
    justifyContent: `space-evenly`,
    height: "65px",
    color: "white",
    background: t => `
    linear-gradient(
      225deg,
      ${t.colors.success} 82%,
      ${t.colors.successLight} 28%)
   `,
  },
  cardheadTeklifler: {
    alignItems: `center`,
    justifyContent: `space-evenly`,
    height: "65px",
    color: "white",
    background: t => `
    linear-gradient(
      225deg,
      ${t.colors.error} 82%,
      ${t.colors.errorLight} 28%)
   `,
  },
  cardheadSatinAlma: {
    alignItems: `center`,
    justifyContent: `space-evenly`,
    height: "65px",
    color: "white",
    background: t => `
    linear-gradient(
      225deg,
      #C07F00 82%,
      #FFD95A 28%)
   `,
  },
  cardheadParametre: {
    alignItems: `center`,
    justifyContent: `space-evenly`,
    height: "65px",
    color: "white",
    background: t => `
    linear-gradient(
      225deg,
      #DA0C81 82%,
      #E95793 28%)
   `,
  },
  cardheadAlisFiyatlar: {
    alignItems: `center`,
    justifyContent: `space-evenly`,
    height: "65px",
    color: "white",
    background: t => `
    linear-gradient(
      225deg,
      #219C90 82%,
      #DAFFFB 28%)
   `,
  },
  cardheadMusteriler: {
    alignItems: `center`,
    justifyContent: `space-evenly`,
    height: "65px",
    color: "white",
    background: t => `
    linear-gradient(
      225deg,
      #5B0888 82%,
      #9D76C1 28%)
   `,
  },
  cardheadTedarikciler: {
    alignItems: `center`,
    justifyContent: `space-evenly`,
    height: "65px",
    color: "white",
    background: t => `
    linear-gradient(
      225deg,
      #618264 82%,
      #B0D9B1 28%)
   `,
  },
  cardheadMakineler: {
    alignItems: `center`,
    justifyContent: `space-evenly`,
    height: "65px",
    color: "white",
    background: t => `
    linear-gradient(
      225deg,
      #C07F00 82%,
      #FFD95A 28%)
   `,
  },
  cardheadKarOranlari: {
    alignItems: `center`,
    justifyContent: `space-evenly`,
    height: "65px",
    color: "white",
    background: t => `
    linear-gradient(
      225deg,
      #1450A3 82%,
      #337CCF 28%)
   `,
  },
  cardbody: {
    alignItems: `center`,
    justifyContent: `center`,
    m: 10
  },
  cardfooter: {
    alignItems: `center`,
    justifyContent: `center`,
    m: 10
  },
  cardfooter2: {
    alignItems: `center`,
    justifyContent: `center`,
    m: -1
  }
}


const DashBoardBlock01 = ({ content: { Data }, ...props }) => {



  const [toplamTeklif, settoplamTeklif] = useState(0);
  const [onaylananTeklif, setonaylananTeklif] = useState(0);
  const [yeniModelList, setyeniModelList] = useState([]);

  const [lisansTarihi, setlLisansTarihi] = useState("");
  const [toplamIndırme, settoplamIndırme] = useState(0);
  const [sonIndirme, setsonIndirme] = useState("");

  const [favoriList, setfavoriList] = useState([]);

  const [lisansBaslik, setlisansBaslik] = useState("");
  const [lisansDurum, setlisansDurum] = useState("");
  const [lisansTarih, setlisansTarih] = useState("");
  const [sayilar, setSayilar] = useState(null);


  const [filterTip, setfilterTip] = useState(0);

  const [popKarOranlari, setpopKarOranlari] = useState(false);
  const handleKarOranlariPopup = () => {
    setpopKarOranlari(!popKarOranlari);

  }
  const [uretimTipiKar, seturetimTipiKar] = useState(0);




  useEffect(() => {

    RaporCek(filterTip);

  }, [])

  function RaporCek(filt) {
    httpServiceToken.post("Locked/YonetimRapor", { params: filt }).then((res) => {
      if (res) {
        if (res.DogruMu) {


          settoplamTeklif(res.Data.ToplamTeklif);
          setonaylananTeklif(res.Data.OnaylananTeklif);
          settoplamIndırme(res.Data.ToplamIndirme);

          let dt = { Data: res.Data.YeniModeller };
          setyeniModelList(dt);
          setsonIndirme(res.Data.SonIndirme);

          setlisansBaslik(res.Data.Lisans.Baslik);
          setlisansDurum(res.Data.Lisans.Durum);
          setlisansTarih(res.Data.Lisans.Tarih);
          setSayilar(res.Data.Sayilar);

          setKtTokenSifre(res.Refresh);

          if (typeof window !== 'undefined') {
            const image = new Image();
            if (res.Data.LisansVarMi)
              image.src = 'https://kututasarimi.com/iyziPages/die-cut-template-licence-ok-kt-1.svg';
            else
              image.src = 'https://kututasarimi.com/iyziPages/die-cut-template-licence-no-kt.svg';


            const plugin = {
              id: 'custom_canvas_background_image',
              beforeDraw: (chart) => {
                if (image.complete) {
                  const ctx = chart.ctx;
                  const { top, left, width, height } = chart.chartArea;
                  const x = left + width / 2 - image.width / 2;
                  const y = top + height / 2 - image.height / 2;
                  ctx.drawImage(image, x, y);



                  // var fontSize = (height / 314).toFixed(2);
                  // ctx.font = fontSize + "em sans-serif";
                  // ctx.fillStyle = "#6D7278";
                  // ctx.textBaseline = "middle";

                  // var text = "14.04.2022",
                  //   textX = width / 2,
                  //   textY = height / 1.5;
                  // ctx.fillText(text, textX, textY);
                } else {
                  image.onload = () => chart.draw();
                }
              }
            };

            const chrOptions = {
              cutout: '70%',
              responsive: true,
              maintainAspectRatio: false,
              aspectRatio: 1,
              plugins: {
                legend: {
                  display: false
                },
                // subtitle: {
                //   display: true,
                //   text: 'Lisans',
                //   padding: {
                //     top: 10,
                //     bottom: 30
                //   }
                // },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      let label = context.label || '';

                      if (label) {
                        label += ' : ';
                      }

                      label += context.dataset.data[context.dataIndex];
                      label += ' gün';

                      return label;
                    },
                    // labelColor: function (context) {
                    //   return {
                    //     borderColor: 'rgb(0, 0, 255)',
                    //     backgroundColor: 'rgb(255, 0, 0)',
                    //     borderWidth: 2,
                    //     borderDash: [2, 2],
                    //     borderRadius: 2,
                    //   };
                    // },
                    // labelTextColor: function (context) {
                    //   return '#543453';
                    // }
                  }
                }
                // title: {
                //   display: true,
                //   text: 'Custom Chart Title',
                //   padding: {
                //     top: 10,
                //     bottom: 30
                //   }
                // }
              },

              tooltips: {
                enabled: false,
              },
              layout: {
                padding: {
                  top: 0,
                  bottom: 0,
                  right: 20,
                  left: 20
                }
              }
            };

            const data = {
              labels: ['Son 1 Yıl Lisanssız', 'Kalan Lisans Süresi', 'Bu Güne Kadar Kullanılan'],
              datasets: [
                {
                  data: res.Data.ChartData,
                  backgroundColor: [
                    '#E25D56',
                    '#FFC000',
                    '#9CBE5A'
                  ],
                  // borderColor: [
                  //   'rgba(255, 99, 132, 1)',
                  //   'rgba(54, 162, 235, 1)',
                  //   'rgba(75, 192, 192, 1)'
                  // ],
                  borderWidth: 2,
                }
              ]
            };


            const ctx = document.getElementById('chart').getContext('2d');
            const chart = new Chart(ctx, {
              type: 'doughnut',
              data: data,
              options: chrOptions,
              plugins: [plugin],
            });

          }

        }
        else {

        }
      } else {
        console.log("logout");
      }
    }).catch((err) => {
      console.log(err);;
    });
  }

  Chart.register(...registerables);
  return (

    <form
      method='POST'
    >
      <Container>
        {/* <Box>
          <div className="dash-tab">
            <Tabs sx={{ flex: 1, p: 20, paddingBottom: 20 }} tabs={["Tümü", "Tahsin Picak", "Mehmet Ali", "Hümeyra Sena"]} onChange=
              {e => {
                setresimTipi(e + 1);
              }}
              variant='pill' /></div>
        </Box> */}
        <Box>
          <div className="dash-tab">
            <Tabs sx={{ flex: 1, p: 20, paddingBottom: 20 }} tabs={["Tümü", "Bu Yıl", "Geçen Ay", "Bu Ay", "Bu Gün"]} onChange=
              {e => {
                setfilterTip(e);
                RaporCek(e);

              }}
              variant='pill'>

              <TabPanel key={`pnl-${0}`} >

              </TabPanel>
              <TabPanel key={`pnl-${1}`} >

              </TabPanel>
              <TabPanel key={`pnl-${2}`} >

              </TabPanel>
              <TabPanel key={`pnl-${3}`} >

              </TabPanel>
              <TabPanel key={`pnl-${4}`} >

              </TabPanel>
            </Tabs>
          </div>

        </Box>
        <Reveal effect='fadeIn'>
          <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>

            <Box sx={styles.wrapper}>
              <ContentContainer
                content={{
                  variant: "cards.interactive",
                }}
                variant='cards.interactive'
                sx={styles.card} >
                <Flex
                  sx={styles.cardhead2}  >
                  <Box sx={{ fontSize: "0.9em" }} className="tooltip-on-hover" > TOPLAM İNDİRME</Box>

                  <AiOutlineCloudDownload size={45} />
                </Flex>
                <Flex
                  variant="gradient"
                  sx={styles.cardbody}  >
                  <Box sx={{ fontSize: "1.8em", color: "black" }} className="tooltip-on-hover" > <Counter to={toplamIndırme} />
                    <div className="tooltip">Toplam İndirme Sayısı</div>
                  </Box>

                </Flex>
                <Flex variant="gradient"
                  sx={styles.cardfooter2}  >

                  <Box sx={{ fontSize: "0.7em" }} >{sonIndirme} </Box>

                </Flex>
                <Flex
                  variant="gradient"
                  sx={styles.cardfooter}  >
                  <Box onClick=
                    {e => {
                      window.location.assign("/ambalaj-web/indirmelerim");
                    }}
                    sx={styles.linkBtn} > İndirme Arşivim   </Box>
                </Flex>
              </ContentContainer>

            </Box>
            <Box sx={styles.wrapper}>
              <ContentContainer
                content={{
                  variant: "cards.interactive",
                }}
                variant='cards.interactive'
                sx={styles.card} >

                <Flex
                  sx={styles.cardheadTeklifler}  >
                  <Box sx={{ fontSize: "0.9em" }} >TEKLİFLER</Box>
                  <AiOutlineFileText size={45} />
                </Flex>

                {sayilar != null && <Flex
                  sx={styles.cardbody}  >
                   <Box sx={{ fontSize: "1.8em", color: "black" }} className="tooltip-on-hover" >   {sayilar.YeniTeklifCnt}  |
                    <Box sx={{ fontSize: "0.25em", color: "gray", textAlign: "center" }} > {sayilar.YeniTeklifTon} <br></br>{sayilar.YeniTeklifTut}</Box>

                    <div className="tooltip">Yeni Teklifler</div>
                  </Box>
                  <Box sx={{ fontSize: "1.2em" }} >
                    <Box
                      className="tooltip-on-hover"
                      sx={{
                        fontSize: "1.2em",
                        color: "gray"
                      }}> &nbsp; <Counter to={sayilar.OnaylananTeklifCnt} />
                      <Box sx={{ fontSize: "0.3em", textAlign: "center", paddingTop: "9px" }} >{sayilar.OnaylananTeklifTon}<br></br>{sayilar.OnaylananTeklifTut}</Box>
                      <div className="tooltip">Onaylanan Teklifler</div>
                    </Box>
                  </Box>

                  <Box sx={{ fontSize: "1.2em" }} >
                    <Box
                      className="tooltip-on-hover"
                      sx={{
                        fontSize: "1.2em",
                        color: "gray"
                      }}> &nbsp; <Counter to={sayilar.ArsivTeklifCnt} />
                      <Box sx={{ fontSize: "0.3em", textAlign: "center", paddingTop: "9px" }} >{sayilar.ArsivTeklifTon} <br></br>{sayilar.ArsivTeklifTut}</Box>
                      <div className="tooltip">Arşivlenen Teklifler</div>
                    </Box> </Box>
                </Flex>}

                <Flex
                  sx={styles.cardfooter}  >
                  <Box onClick=
                    {e => {
                      window.location.assign("/ambalaj-web/musteri-teklifleri");
                    }}
                    sx={styles.linkBtn} > Teklif Listesi   </Box>
                </Flex>
              </ContentContainer>
            </Box>

            <Box sx={styles.wrapper}>
              <ContentContainer
                content={{
                  variant: "cards.interactive",
                }}
                variant='cards.interactive'
                sx={styles.card} >

                <Flex
                  sx={styles.cardheadSiparisler}  >
                  <Box sx={{ fontSize: "0.9em" }} > SİPARİŞLER</Box>
                  <AiOutlineFileDone size={45} />
                </Flex>

                {sayilar != null && <Flex
                  sx={styles.cardbody}  >
                  <Box sx={{ fontSize: "1.8em", color: "black" }} className="tooltip-on-hover" > {sayilar.BekleyenSipCnt}  |
                    <Box sx={{ fontSize: "0.25em", color: "gray", textAlign: "center" }} >{sayilar.BekleyenSipTon} <br></br>{sayilar.BekleyenSipTut}</Box>

                    <div className="tooltip">Bekleyen Siparişler</div>
                  </Box>
                  <Box sx={{ fontSize: "1.2em" }} >
                    <Box
                      className="tooltip-on-hover"
                      sx={{
                        fontSize: "1.2em",
                        color: "gray"
                      }}> &nbsp; <Counter to={sayilar.TeslimEdilenSipCnt} />
                      <Box sx={{ fontSize: "0.3em", textAlign: "center", paddingTop: "9px" }} >{sayilar.TeslimEdilenSipTon} <br></br>{sayilar.TeslimEdilenSipTut}</Box>
                      <div className="tooltip">Teslim Edilen Siparişler</div>
                    </Box> </Box>
                </Flex>}


                <Flex
                  variant="gradient"
                  sx={styles.cardfooter}  >
                  <Box onClick=
                    {e => {
                      window.location.assign("/ambalaj-web/siparisler");
                    }}
                    sx={styles.linkBtn} >  Sipariş Listesi</Box>
                </Flex>
              </ContentContainer>
            </Box>
          </Flex>
          <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
            <Box sx={styles.wrapper}>
              <ContentContainer
                content={{
                  variant: "cards.interactive",
                }}
                variant='cards.interactive'
                sx={styles.card} >
                <Flex
                  sx={styles.cardheadSatinAlma}  >
                  <Box sx={{ fontSize: "0.9em" }} > SATIN ALMA</Box>
                  <AiOutlineLayout size={45} />
                </Flex>

                {sayilar != null && <Flex
                  sx={styles.cardbody}  >
                  <Box sx={{ fontSize: "1.8em", color: "black" }} className="tooltip-on-hover" > {sayilar.BekleyenSatinAlmaCnt}  |
                    <Box sx={{ fontSize: "0.25em", color: "gray", textAlign: "center" }} >{sayilar.BekleyenSatinAlmaTon} <br></br>{sayilar.BekleyenSatinAlmaTut}</Box>

                    <div className="tooltip">Bekleyen Satın Almalar</div>
                  </Box>
                  <Box sx={{ fontSize: "1.2em" }} >
                    <Box
                      className="tooltip-on-hover"
                      sx={{
                        fontSize: "1.2em",
                        color: "gray"
                      }}> &nbsp; <Counter to={sayilar.TeslimAlinanSatinAlmaCnt} />
                      <Box sx={{ fontSize: "0.3em", textAlign: "center", paddingTop: "9px" }} >{sayilar.TeslimAlinanSatinAlmaTon}<br></br>{sayilar.TeslimAlinanSatinAlmaTut}</Box>
                      <div className="tooltip">Teslim Alınan Satın Almalar</div>
                    </Box> </Box>
                </Flex>}

                <Flex
                  variant="gradient"
                  sx={styles.cardfooter}  >
                  <Box onClick=
                    {e => {
                      window.location.assign("/satin-alma");
                    }}
                    sx={styles.linkBtn} > Satın Alma Listesi</Box>
                </Flex>
              </ContentContainer>

            </Box>
            <Box sx={styles.wrapper}>
              <ContentContainer
                content={{
                  variant: "cards.interactive",
                }}
                variant='cards.interactive'
                sx={styles.card} >

                <Flex
                  sx={styles.cardhead1}  >
                  <Box sx={{ fontSize: "0.9em" }} > ÜRETİM</Box>
                  <AiOutlineBuild size={45} />
                </Flex>
                {sayilar != null && <Flex
                  sx={styles.cardbody}  >
                  <Box sx={{ fontSize: "1.8em", color: "black" }} className="tooltip-on-hover" > {sayilar.BekleyenIsCnt}   |
                    <Box sx={{ fontSize: "0.25em", color: "gray", textAlign: "center" }} >{sayilar.BekleyenIsTon} <br></br>{sayilar.BekleyenIsTut}</Box>

                    <div className="tooltip">Bekleyen İşler</div>
                  </Box>
                  <Box sx={{ fontSize: "1.2em" }} >
                    <Box
                      className="tooltip-on-hover"
                      sx={{
                        fontSize: "1.2em",
                        color: "gray"
                      }}> &nbsp; <Counter to={sayilar.UretilenIsCnt} /> |
                      <Box sx={{ fontSize: "0.3em", textAlign: "center", paddingTop: "9px" }} >{sayilar.UretilenIsTon} <br></br>{sayilar.UretilenIsTut}</Box>
                      <div className="tooltip">Üretilen İşler</div>
                    </Box> </Box>
                  <Box sx={{ fontSize: "1.2em" }} >
                    <Box
                      className="tooltip-on-hover"
                      sx={{
                        fontSize: "1.2em",
                        color: "gray"
                      }}> &nbsp; <Counter to={sayilar.TeslimEdilenIsCnt} />
                      <Box sx={{ fontSize: "0.3em", textAlign: "center", paddingTop: "9px" }} >{sayilar.TeslimEdilenIsTon} <br></br>{sayilar.TeslimEdilenIsTut}</Box>
                      <div className="tooltip">Teslim Edilen İşler</div>
                    </Box> </Box>
                </Flex>}

                <Flex
                  variant="gradient"
                  sx={styles.cardfooter}  >
                  <Box onClick=
                    {e => {
                      window.location.assign("/uretim-takip");
                    }}
                    sx={styles.linkBtn} > İş Emirleri Listesi </Box>
                </Flex>
              </ContentContainer>
            </Box>
            <Box sx={styles.wrapper}>
              <ContentContainer
                content={{
                  variant: "cards.interactive",
                }}
                variant='cards.interactive'
                sx={styles.card} >
                <Flex
                  sx={styles.cardbody}  >
                  <Box sx={{ fontSize: "2.9em" }} >
                    <div className="chart">
                      <canvas id="chart"></canvas>
                    </div> </Box>   <Box sx={{ fontSize: "0.7em" }} >

                    <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: 10 }}>
                      <Box  >
                        {lisansBaslik}
                      </Box>
                      <br />
                      <Box >{lisansDurum}</Box>
                      <br />
                      <Box >{lisansTarih}</Box>
                    </Flex>

                  </Box>
                </Flex>
              </ContentContainer>
            </Box>
          </Flex>
          <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
            <Box sx={styles.wrapper}>
              <ContentContainer
                content={{
                  variant: "cards.interactive",
                }}
                variant='cards.interactive'
                sx={styles.cardYarim} >

                <Flex
                  sx={styles.cardheadParametre}  >
                  <Box sx={{ fontSize: "0.9em" }} > PARAMETRELER</Box>
                  <AiOutlineControl size={45} />
                </Flex>
                <Flex
                  variant="gradient"
                  sx={styles.cardfooter}  >
                  <Divider space="3" />
                  <Box onClick=
                    {e => {
                      window.location.assign("/ambalaj-web/parametreler");
                    }}
                    sx={styles.linkBtn} > Parametre ve Ayarlar</Box>
                </Flex>
              </ContentContainer>

            </Box>
            <Box sx={styles.wrapper}>
              <ContentContainer
                content={{
                  variant: "cards.interactive",
                }}
                variant='cards.interactive'
                sx={styles.cardYarim} >
                <Flex
                  sx={styles.cardheadKarOranlari}  >
                  <Box sx={{ fontSize: "0.9em" }} > KAR ORANLARI</Box>
                  <AiFillProject size={45} />
                </Flex>
                <Flex
                  variant="gradient"
                  sx={styles.cardfooter}  >
                  <Divider space="3" />
                  <Box onClick=
                    {e => {
                      seturetimTipiKar(10);
                      setpopKarOranlari(true);
                    }}
                    sx={styles.linkBtn} >Oluklu Kutu Kar Oranlarını Değiştir</Box>
                </Flex>
                <Flex
                  variant="gradient"
                  sx={styles.cardfooter}  >
                  <Box onClick=
                    {e => {
                      seturetimTipiKar(20);
                      setpopKarOranlari(true);

                    }}
                    sx={styles.linkBtn} >Karton Kutu Kar Oranlarını Değiştir</Box>
                </Flex>
              </ContentContainer>
            </Box>
            <Box sx={styles.wrapper}>

              {/* <ContentContainer
                content={{
                  variant: "cards.interactive",
                }}
                variant='cards.interactive'
                sx={styles.cardYarim} >
                <Flex
                  sx={styles.cardheadMusteriler}  >
                  <Box sx={{ fontSize: "0.9em" }} > MÜŞTERİLER</Box>
                  <AiFillContacts size={45} />
                </Flex>
                <Flex
                  variant="gradient"
                  sx={styles.cardfooter}  >
                  <Divider space="3" />
                  <Box onClick=
                    {e => {
                      window.location.assign("/ambalaj-web/siparisler");
                    }}
                    sx={styles.linkBtn} > Müşteri Listesi</Box>
                </Flex>
              </ContentContainer> */}
            </Box>

          </Flex>
          <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: -1 }}>
            <Box sx={styles.wrapper}>
              {/* <ContentContainer
                content={{
                  variant: "cards.interactive",
                }}
                variant='cards.interactive'
                sx={styles.cardYarim} >

                <Flex
                  sx={styles.cardheadTedarikciler}  >
                  <Box sx={{ fontSize: "0.9em" }} > TEDARİKÇİLER</Box>
                  <AiTwotoneShop size={45} />
                </Flex>
                <Flex
                  variant="gradient"
                  sx={styles.cardfooter}  >
                  <Divider space="3" />
                  <Box onClick=
                    {e => {
                      window.location.assign("/ambalaj-web/is-emri-listesi");
                    }}
                    sx={styles.linkBtn} > Tedarikçi Listesi</Box>
                </Flex>
              </ContentContainer> */}

            </Box>
            <Box sx={styles.wrapper}>
              {/* <ContentContainer
                content={{
                  variant: "cards.interactive",
                }}
                variant='cards.interactive'
                sx={styles.cardYarim} >
                <Flex
                  sx={styles.cardheadMakineler}  >
                  <Box sx={{ fontSize: "0.9em" }} > MAKİNELER</Box>
                  <AiOutlineMacCommand size={45} />
                </Flex>
                <Flex
                  variant="gradient"
                  sx={styles.cardfooter}  >
                  <Divider space="3" />
                  <Box onClick=
                    {e => {
                      window.location.assign("/ambalaj-web/siparisler");
                    }}
                    sx={styles.linkBtn} > Makine Listeleri ve Makine Ayarları</Box>
                </Flex>
              </ContentContainer> */}
            </Box>
            <Box sx={styles.wrapper}>
              {/* <ContentContainer
                content={{
                  variant: "cards.interactive",
                }}
                variant='cards.interactive'
                sx={styles.cardYarim} >
                <Flex
                  sx={styles.cardheadAlisFiyatlar}  >
                  <Box sx={{ fontSize: "0.9em" }} > ALIŞ FİYATLARI</Box>
                  <AiOutlineNumber size={45} />
                </Flex>
                <Flex
                  variant="gradient"
                  sx={styles.cardfooter}  >
                  <Divider space="3" />
                  <Box onClick=
                    {e => {
                      window.location.assign("/ambalaj-web/siparisler");
                    }}
                    sx={styles.linkBtn} > Alış Fiyatları Toplu Güncelleme</Box>
                </Flex>
              </ContentContainer>
               */}
            </Box>
          </Flex>
          <Flex sx={{ flexWrap: `wrap`, justifyContent: `center`, m: 4 }}>
            {yeniModelList != null ? (
              yeniModelList.Data != null ?
                yeniModelList.Data.length > 0 ?
                  <Slider content={yeniModelList} Baslik="Yeni Modeller"></Slider> : <></> : <></>) : (
              <div></div>
            )}
          </Flex>
        </Reveal>
        {popKarOranlari == true && <KarOranlariEdit
          addEventOpen={popKarOranlari}
          handlePopup={handleKarOranlariPopup}
          detayName={uretimTipiKar == 10 ? "Oluklu Üretimi Kar Oranları Güncelleme" : "Karton Üretimi Kar Oranları Güncelleme"}
          listRefreshEvent={handleKarOranlariPopup}
          uretimTipi={uretimTipiKar}
        />
        }
      </Container>
    </form>
  )
}

export default WithDefaultContent(DashBoardBlock01)

