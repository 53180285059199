import React, { useEffect, useContext, useState, useRef, useCallback } from 'react'
import { Container, Flex, Box, Heading, Radio, IconButton, css, Text, Select, Button } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import TeklifTextField from '@solid-ui-components/ContentForm/TeklifTextField'
import TeklifInput from '@solid-ui-components/ContentForm/TeklifInput'

import useForm from '@helpers/useForm'
import { FormContext } from '@solid-ui-components/ContentForm'
import httpService from '../../../../../../site/src/service/httpService'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ReactModal from 'react-modal'
import { FaRegStar, FaPlus, FaHandHoldingHeart, FaHandHoldingMedical } from 'react-icons/fa'
import Slider from '@solid-ui-blocks/Slider/Block01'
import './styles.css'
import loadingGif from '../../../assets/model-cizim-kutu-tasarimi2.gif'
import parse from 'html-react-parser'
import { setKtTokenSifre, getKtLogin, logOut } from '../../../../../../site/src/service/auth'
import loadsGif from '../../../assets/loads.gif'
import loadsBeyazGif from '../../../assets/loadsBeyaz.gif'
import indirPng from '../../../assets/online-paskage-pdf-die-cut.png'
import favoriPng from '../../../assets/favori-bicak-izi-pdf.png'
import { ModalContext } from '@solid-ui-components/Modal'
// import indirmeCss from './_bicakizi.css' //style={indirmeCss}
import Tabs from '@solid-ui-components/Tabs'
// import { Tab } from 'react-tabs'
import { TabsContext } from '@solid-ui-components/Tabs'
import { AiOutlineCloseCircle, AiFillCloseSquare, AiOutlineDelete } from "react-icons/ai";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import Icon from '@solid-ui-components/ContentIcon'
import Reveal from '@solid-ui-components/Reveal'
import toast, { Toaster } from 'react-hot-toast'

const styles = {
  accordion: {
    '.accordion__button': {
      cursor: `pointer`,
      position: `relative`,
      outline: `none`,
      transition: `all 0.15s ease-in`
    },
    '.accordion__button:hover': {
      opacity: 0.75,
      '&::before': {
        borderColor: `beta`
      }
    },
    '.accordion__button::before': {
      content: `' '`,
      width: `10px`,
      height: `10px`,
      marginRight: `12px`,
      borderBottom: `3px solid currentColor`,
      borderLeft: `3px solid currentColor`,
      position: `absolute`,
      right: 4,
      top: `50%`,
      transform: `translate(0, -50%) rotate(45deg)`,
      transition: `all 0.15s ease-in`
    },
    '.accordion__button[aria-expanded="true"]::before, .accordion__button[aria-selected="true"]::before': {
      transform: `translate(0, -50%) rotate(-45deg)`,
      transition: `transform 0.35s ease-in`
    },
    '[hidden]': {
      display: `none`
    },
    '.accordion__panel': {
      animation: `fadeIn 0.25s ease-in`
    },
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0.5,
        transform: 'translate(0, -15%)'
      },
      '100%': {
        opacity: 1,
        transform: 'translate(0, 0%)'
      }
    }
  }
  ,
  listItem: {
    alignItems: `stretch`,
    justifyContent: `flex-start`
  },
  line: {
    position: `absolute`,
    bottom: `10px`,
    left: `50%`,
    width: `1px`,
    height: t => `calc(100% - ${t.sizes.icon.md + 20}px)`,
    transform: `translateX(-50%)`,
    zIndex: 1,
    bg: `omega`
  },
  number: {
    color: `white`,
    fontWeight: `body`,
    borderRadius: `full`,
    bg: `alpha`,
    size: `icon.md`,
    textAlign: `center`,
    p: 2,
    mb: 0
  },


  modalContent2: {
    borderRadius: `lg`,
    bg: `contentBg`,
    maxWidth: `container`,
    overflow: `hidden`,
    py: 4,
    px: 4
  },
  close: {
    position: `absolute`,
    size: [`icon.sm`, null, `icon.md`],
    zIndex: 99,
    width: 50,
    height: 50

  },
  td22: {
    width: 22 / 100 + '%',

  },
  tdAralikBos: {
    textAlign: "center",
    fontSize: "x-small"
  },
  tdAralikInp: {
    textAlign: "center"
  },
  tdKarBir: {
    textAlign: "center"
  },
  tdKarInp: {
    textAlign: "center"
  },
  tdKarBos: {
    textAlign: "right"
  },
}

const KarOranlariEdit = ({ ...props }) => {

  const {
    addEventOpen,
    handlePopup,
    detayName,
    listRefreshEvent,
    uretimTipi
  } = props






  const [firtProcess, setfirtProcess] = useState("1");

  const [karOrani, setkarOrani] = useState(null);



  useEffect(async () => {
    if (typeof window !== 'undefined' && firtProcess == "1") {
      const ktLoginMiii = await getKtLogin();
      if (ktLoginMiii == true) {
        setfirtProcess("2");
        GetList();

      }
      else {
        window.location.assign("/");

      }
    }

  }, [firtProcess]);


  function GetList() {
    httpServiceToken.post("Locked/KarOrani", { params: uretimTipi }).then((res) => {
      setkarOrani(null);
      if (res.DogruMu) {
        if (res.Data) {
          setkarOrani(res.Data);

          setoran0(res.Data.Aralik0);
          setoran1(res.Data.Aralik1);
          setoran2(res.Data.Aralik2);
          setoran3(res.Data.Aralik3);
          setoran4(res.Data.Aralik4);

          setkar1(res.Data.KarOrani1);
          setkar2(res.Data.KarOrani2);
          setkar3(res.Data.KarOrani3);
          setkar4(res.Data.KarOrani4);


        }
      } else {

      }
    }).catch((err) => {
      console.log(err);
    });
  }

  const [kar1, setkar1] = useState(55);
  const [kar2, setkar2] = useState(30);
  const [kar3, setkar3] = useState(20);
  const [kar4, setkar4] = useState(15);

  const [oran0, setoran0] = useState(0);
  const [oran1, setoran1] = useState(1000);
  const [oran2, setoran2] = useState(2000);
  const [oran3, setoran3] = useState(5000);
  const [oran4, setoran4] = useState(10000);






  return (

    <ReactModal
      id={"karOraniModals"}
      isOpen={addEventOpen}
      onRequestClose={() => handlePopup(false)}
      closeTimeoutMS={300}
      className='CustomModalPortalContent'
      overlayClassName='ModalPortalOverlay'
      shouldFocusAfterRender={false}
      sx={css({ ...styles.modalContent2 })}
      {...props}
    >
      <Flex
        sx={{
          alignItems: `center`,
          flexDirection: [
            `column`,
            `row`
          ]
        }}
      >
        <Box sx={{ flex: 1, py: [3, 4], px: [3, 5] }}>
          <Box sx={{ textAlign: `center` }}>
            <ContentText content={{
              text: detayName,
              color: "omegaDarker",
              variant: "h5",
              space: 2
            }} />

            <Box sx={{ textAlign: `left` }}>
              <ContentText content={{
                text: uretimTipi == 10 ? "Oluklu ambalaj üretiminde kar oranı analizleri METRE KARE (m2) baz alınarak tasarlanmıştır" : "Karton ambalaj üretiminde kar oranları AMBALAJ ADETİ alınarak tasarlanmıştır",
                color: "omegaDark",
              }} />
            </Box>
            <Box sx={{ textAlign: `left` }}>
              <ContentText content={{
                text: 10 ? "Böylece kullanılacak oluklu metrekaresine göre kar oranı belirleyebilirsiniz":"Böylece üretilecek ambalaj adetine göre kar oranı belirleyebilirsiniz",
                color: "omegaDark",
              }} />
            </Box>
            <Box sx={{ textAlign: `left` }}>
              <ContentText content={{
                text: "Bu oranlar sizlere yardımcı olabilmek için oluşturulmuştur. Satış fiyatını istediğiniz gibi girebilirsiniz.",
                color: "omegaDark",
              }} />
            </Box>
          </Box>
          <Box>
            <Divider space={1} />
            {karOrani && <Flex
              sx={{
                alignItems: `center`,
                flexDirection: [
                  `column`,
                  `row`
                ]
              }}
            >

              <Box
                sx={{
                  display: [`none`, null, `block`],
                  flex: 1,
                  p: '0.9rem',
                  borderRadius: `left`,
                  overflow: `hidden`
                }}
              >

                <table style={{ width: '100%' }}>
                  <tbody>
                    {/* <tr>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                      <td style={styles.td22}></td>
                    </tr> */}
                    <tr>
                      <td style={styles.tdAralikBos} colSpan={4} > </td>
                      <td style={styles.tdAralikInp} colSpan={2} >  <TeklifInput
                        {...props}
                        value={oran1}
                        onChange={(value) => {
                          setoran1(value.target.value);
                        }}
                        placeholder=""
                      /> </td>
                      <td style={styles.tdAralikBos} colSpan={4} >  </td>
                      <td style={styles.tdAralikInp} colSpan={2} >  <TeklifInput
                        {...props}
                        value={oran2}
                        onChange={(value) => {
                          setoran2(value.target.value);
                        }}
                        placeholder=""
                      /> </td>
                      <td style={styles.tdAralikBos} colSpan={4} ></td>
                      <td style={styles.tdAralikInp} colSpan={2} >  <TeklifInput
                        {...props}
                        value={oran3}
                        onChange={(value) => {
                          setoran3(value.target.value);
                        }}
                        placeholder=""
                      /> </td>
                      <td style={styles.tdAralikBos} colSpan={4} > </td>
                    </tr>
                    {uretimTipi == 10 && <tr>
                      <td style={styles.tdAralikBos} colSpan={4} > {oran1}m2 ye Kadar</td>
                      <td style={styles.tdAralikInp} colSpan={2} >  </td>
                      <td style={styles.tdAralikBos} colSpan={4} >  {oran1}m2-{oran2}m2 </td>
                      <td style={styles.tdAralikInp} colSpan={2} >  </td>
                      <td style={styles.tdAralikBos} colSpan={4} > {oran2}m2-{oran3}m2</td>
                      <td style={styles.tdAralikInp} colSpan={2} >   </td>
                      <td style={styles.tdAralikBos} colSpan={4} >{oran3}m2 ve üzeri</td>
                    </tr>
                    }
                    {uretimTipi == 20 && <tr>
                      <td style={styles.tdAralikBos} colSpan={4} > {oran1}adete Kadar</td>
                      <td style={styles.tdAralikInp} colSpan={2} >  </td>
                      <td style={styles.tdAralikBos} colSpan={4} >  {oran1}adet-{oran2}adet </td>
                      <td style={styles.tdAralikInp} colSpan={2} >  </td>
                      <td style={styles.tdAralikBos} colSpan={4} > {oran2}adet-{oran3}adet</td>
                      <td style={styles.tdAralikInp} colSpan={2} >   </td>
                      <td style={styles.tdAralikBos} colSpan={4} >{oran3}adet ve üzeri</td>
                    </tr>
                    }
                    <tr>
                      <td style={styles.tdKarBir} >% </td>
                      <td style={styles.tdKarInp} colSpan={2} ><TeklifInput
                        {...props}
                        value={kar1}
                        onChange={(value) => {
                          setkar1(value.target.value);
                        }}
                        placeholder=""
                      /> </td>
                      <td style={styles.tdKarBos} colSpan={4} >% </td>
                      <td style={styles.tdKarInp} colSpan={2} ><TeklifInput
                        {...props}
                        value={kar2}
                        onChange={(value) => {
                          setkar2(value.target.value);
                        }}
                        placeholder=""
                      /> </td>
                      <td style={styles.tdKarBos} colSpan={4} >% </td>
                      <td style={styles.tdKarInp} colSpan={2} ><TeklifInput
                        {...props}
                        value={kar3}
                        onChange={(value) => {
                          setkar3(value.target.value);
                        }}
                        placeholder=""
                      /> </td>
                      <td style={styles.tdKarBos} colSpan={4} >% </td>
                      <td style={styles.tdKarInp} colSpan={2} ><TeklifInput
                        {...props}
                        value={kar4}
                        onChange={(value) => {
                          setkar4(value.target.value);
                        }}
                        placeholder=""
                      /> </td>
                      <td style={styles.tdKarBir} > </td>
                    </tr>
                  </tbody>
                </table>

              </Box>
            </Flex>}


            <Divider space={3} />
          </Box>
          <Box sx={{ flex: 1, py: [1, 1], px: [2, 3] }}>
            <div className="popup-btns">
              <Button type="submit"
                aria-label="Popup kapat"
                variant="dark"

                id={"btnGeriDon"}
                onClick={e => {
                  handlePopup();
                  listRefreshEvent();


                }}
              >
                Kapat
              </Button>



              {karOrani && <Button type="submit"
                id={"btnKaydet"}
                aria-label=" Vade Türü Kaydet"
                onClick={e => {
                  var dt = {
                    Id: karOrani.Id,
                    Aralik0: oran0,
                    Aralik1: oran1,
                    Aralik2: oran2,
                    Aralik3: oran3,
                    Aralik4: oran4,
                    KarOrani1: kar1,
                    KarOrani2: kar2,
                    KarOrani3: kar3,
                    KarOrani4: kar4
                  };
                  httpServiceToken.post("Locked/KarOraniGuncelle", { params: dt }).then((res) => {
                    if (res.DogruMu) {
                      toast.success(res.Mesaj, {
                        duration: 5000,
                        position: 'top-left'
                      });
                      GetList();
                      handlePopup();
                      listRefreshEvent();
                    } else {
                      toast.error(res.Mesaj, {
                        duration: 5000,
                        position: 'top-left'
                      });
                    }
                  }).catch((err) => {

                  });
                }}
              >
                Değişiklikleri Kaydet
              </Button>
              }
            </div>
          </Box>
        </Box>

      </Flex>

    </ReactModal>
  )
}


export default WithDefaultContent(KarOranlariEdit)


