import React, { useEffect, useContext, useState } from 'react'
import { Box, Text } from 'theme-ui'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import { getKtToken, logOut, setRefToken, getCokW,getKtLogin } from '../../../../../../site/src/service/auth'
import httpServiceToken from '../../../../../../site/src/service/httpServiceToken'
import lockGif from '../../../assets/lock-kutu-tasarimi.png'
import loadsGif from '../../../assets/loads.gif'
import Divider from '@solid-ui-components/Divider'

import { ModalContext } from '@solid-ui-components/Modal'
import config from "../../../../../../site/src/core/config";



const KtLog = ({ children }) => {
  const { setActiveModal } = useContext(ModalContext)
  const [lck, setLck] = useState(null);


  // const user = getKtToken();

  // useEffect(async () => {

  //   try {
  //     if (user) {
  //       if (user.tokenKey)
  //         httpServiceToken.post("Locked/Kontrol",
  //           { params: user.tokenKey }
  //         ).then((res) => {
  //           if (res) {
  //             if (res.Message) {
  //               logOut();
  //               setLck(false);
  //             } else {
  //               if (res.DogruMu) {
  //                 setLck(true);
  //                 if (res.Refresh == true) {
  //                   setRefToken();
  //                 }
  //               } else {
  //                 // logOut();
  //                 // setLck(false);
  //               }
  //             }
  //           } else {
  //             logOut();
  //             setLck(false);
  //           }
  //         }).catch((err) => {
  //           logOut();
  //           setLck(false);
  //         });
  //       else {
  //         logOut();
  //         setLck(false);
  //       }
  //     }
  //     else {
  //       logOut();
  //       setLck(false);
  //     }
  //   } catch (error) {

  //   }
  // }, [lck])



  useEffect(async () => {
  
    const ktLoginMiii = await getKtLogin();
    if (ktLoginMiii == true) {
     // console.log("ktLog");
      setLck(true);
    } else {
      logOut();
      setLck(false);
    }
  }, [lck]);


  return (
    <>
      {lck == null ? <div className='dv-l'>
        <Divider space="2" />
        <img src={loadsGif} alt="online kutu tasarımı" style={{ width: "200px", objectFit: "contain" }} />
        <Divider space="5" />

      </div> :
        lck == true ?
          <div> {children} </div> : <div className='dv-l'>
            <Box>
              <Text key={`lblicolcumesaj`} as='span' color='alpha' sx={{

              }} >
                <h2 > {"Zaman Aşımı Nedeni İle Oturum Kapatıldı"} </h2></Text>
            </Box>
            <img src={lockGif} alt="lock..." style={{ width: "200px", objectFit: "contain" }} />
            <Box>
              <Text key={`lblicolcumesaj`} as='span' color='alpha' sx={{

              }} > <h1 onClick={e => {
                setActiveModal('authentication')
              }} style={{ cursor: "pointer", textDecoration: "underline" }}>

                  {"Giriş Yap"} </h1>      </Text>
            </Box>
          </div>
      }
    </>
  )

}

export default WithDefaultContent(KtLog)
